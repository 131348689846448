import React from "react"
import Seo from "../components/site-metadata"
import Header from "../components/header"
import Container from "../components/container"
import { graphql } from "gatsby"
import Footer from "../components/footer"

export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0]
  return (
    <>
      <Seo title={post.title} description={post.excerpt} image={""} />
      <Header />
      <Container>
        <section className="mb-12 md:px-64">
          <article className="article w-2/3 mx-auto lg:mt-24">
            <h1 className="title leading-tight lg:leading-snug text-2xl lg:text-4xl font-bold">
              {post.title}
            </h1>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </article>
        </section>
      </Container>
      <Footer />
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
